import React, { useEffect, useState } from 'react'
import { VideoRoom } from './video-room';
import { Button } from 'react-bootstrap';
import { IVideoService, VideoService } from '../../services/video-service';

const APP_ID = "929ff2d53b44449d839fb826511b35f0";
export interface IAgoraContainer{
  channelName: string;
  leaveChannel: boolean;
  onLeaveChannel: () => void;
}

export function AgoraContainer(props: IAgoraContainer) {

  const {channelName, leaveChannel, onLeaveChannel} = props;

  const [joined, setJoined] = useState(false);
  const [token, setToken] = useState("");

  const videoService: IVideoService = new VideoService();

  useEffect(()=>{

    const startVideoCall = async ()=> {
      await createToken(channelName)
      setJoined(true);
    }

    startVideoCall();
  },[])

  const handleLeaveChannel = ()=> {
    setJoined(false);
    setToken("");
    onLeaveChannel();
  }

  const createToken = async (channelName: string) => {
    await videoService
      .createToken(channelName)
      .then((data) => {
        if (data) {
          setToken(data);
        }
      });
  };

  return(
     <div>
       {joined && token && 
       (<VideoRoom 
            onLeaveChannel={handleLeaveChannel}
            appId ={APP_ID}
            token={token}
            channelName={channelName}
            leaveChannel={leaveChannel}/>
        )}
     </div>
  );

}
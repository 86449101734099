import React, { useEffect, useState } from "react";
import { IMemorizationSession } from '../../models/memorization-session';
import Table from 'react-bootstrap/Table';
import Form from "react-bootstrap/esm/Form";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SessionStatus } from "../../enums/status-enums";
import MemorizationSessionTeacherModal from "./memorization-session-teacher-modal";
import { UserHasRoles } from "../../user/role";
import { useUserContext } from "../../user/user-context";
import MemorizationSessionStudentModal from "./memorization-session-student-modal";
import { ICompleteMemorizationSessionRequest } from "../../models/complete-memorization-session-request";
import AgoraModal from "../agora/agora-modal";
import { Button } from "react-bootstrap";
import { GetFormattedTimeString } from "../../helpers/date-helper";
import { IMemorization } from "../../models/memorization";

export interface IMemorizationSessionsTable{
  memorizationSessions: IMemorizationSession[];
  memorization: IMemorization;
  teacherMemorizationSessions: IMemorizationSession[];
  onStartSession: (memorizationSessionId: string) => void;
  onAddBackToQueue: (studentId: string)=> {};
  onCompleteSession: (completeMemorizationSessionRequest: ICompleteMemorizationSessionRequest)=> {};
  onDeleteSession: (memorizationSessionId: string) => void;
  onTabStudentTabMemorizationSession: () => void;
  onTabTeacherTabMemorizationSession: () => void;
  onClickMemorizationRowTeacher: (studentId: string) => void;
}

export function MemorizationSessionTable(props: IMemorizationSessionsTable){

  const {memorizationSessions, teacherMemorizationSessions, memorization,
    onStartSession, onAddBackToQueue, 
    onCompleteSession, onDeleteSession,
    onTabStudentTabMemorizationSession, onTabTeacherTabMemorizationSession, onClickMemorizationRowTeacher} = props;

  const [openModal, setOpenModal] = useState(false);

  const [showAgoraModal, setshowAgoraModal] = useState(false);

  const [memorizationSessionRow, setMemorizationSessionRow] = useState({
    memorizationSession: {} as IMemorizationSession
  });

  const [memorizationSessionRowTeacher, setMemorizationSessionRowTeacher] = useState({
    memorizationSession: {} as IMemorizationSession
  });

  const {userContext} = useUserContext();

  const [tabKey, setTabKey] = useState(1);

  const isStudent = UserHasRoles(userContext,["Student"]) && !UserHasRoles(userContext,["Teacher"]);
  const [channelNameState, setChannelName ] = useState(isStudent ? userContext.number : "")

  useEffect(()=>{
    if(Number(tabKey) === 2){
        if(memorization.student){
          let memSessionLocal = {...memorizationSessionRowTeacher.memorizationSession, memorization: memorization}
          setMemorizationSessionRowTeacher({memorizationSession: memSessionLocal});
        }

         setOpenModal(true);
    }
  },[memorization]);

  const rowClickStudent = (memorizationId: string) => {
     setMemorizationSessionRow({memorizationSession: memorizationSessions.filter(x=> x.memorizationSessionId === memorizationId)[0]});
     setOpenModal(true);
  }

  const rowClickTeacher = (studentId: string) => {
    console.log(studentId);
    setMemorizationSessionRowTeacher({memorizationSession: teacherMemorizationSessions.filter(x=> x.student.studentId=== studentId)[0]});
    onClickMemorizationRowTeacher(studentId);
 }

  const handleOnHideModal = () => {
    setOpenModal(false);
    setMemorizationSessionRow({memorizationSession: {} as IMemorizationSession});
    setMemorizationSessionRowTeacher({memorizationSession: {} as IMemorizationSession});
  }

  const handleOnSelectTab = (key: any) => {
    setTabKey(key);

    if(key === "1"){
      onTabStudentTabMemorizationSession();
    }
    if(key === "2"){
      onTabTeacherTabMemorizationSession();
    }
  }

  const onClickStartSession = (memorizationSessionId: string) => {
    onStartSession(memorizationSessionId);
    setOpenModal(false);
  }

  const onClickAddBackToQueue = (studentId: string) => {
    onAddBackToQueue(studentId);
  }

  const onClickCompleteSession = (completeMemorizationSessionRequest: ICompleteMemorizationSessionRequest) => {
    onCompleteSession(completeMemorizationSessionRequest);
  }

  const onClickDeleteSession = (memorizationSessionId: string) => {
    onDeleteSession(memorizationSessionId);
    setOpenModal(false);
  }

  const handleChangeChannelName = (event: any) => {
    setChannelName(event.target.value as string);
  }

  const startVideoCall = ()=> {
    setshowAgoraModal(true);
  }

  const hideAgoraModal = ()=> {
    setshowAgoraModal(false);
  }

  return (
    <>
     {UserHasRoles(userContext,["Administrator","Teacher"]) &&
           <MemorizationSessionTeacherModal showModal={openModal} 
            onHideModal={handleOnHideModal} onStartSession= {onClickStartSession}
            onAddBackToQueue={onClickAddBackToQueue} onCompleteSession ={onClickCompleteSession}
            memorizationSession={Number(tabKey) === 1 
              ? memorizationSessionRow.memorizationSession 
              : memorizationSessionRowTeacher.memorizationSession} />
    }
           
      {memorizationSessionRow.memorizationSession.student && UserHasRoles(userContext,["Student"]) &&
            <MemorizationSessionStudentModal showModal={openModal} 
           onHideModal={handleOnHideModal} onDeleteSession= {onClickDeleteSession}
            memorizationSession={memorizationSessionRow.memorizationSession} />
      }
      <Tabs
          activeKey={tabKey}
          onSelect={(key: any) => handleOnSelectTab(key)}
          id="session-tab-id"
          className="mb-3"
          style={{ backgroundColor:"white" }}
        >
        <Tab  eventKey={1} title="Waiting list">
        <div style={{overflowY: "auto", height:"400px"}}>
          <Table striped bordered hover>
            <thead style={{ 
              position: "sticky", 
              top: 0, 
              background:"#135128", 
              color:"white", 
              fontFamily:"sans-serif",
              fontSize:"15px"}}>
              <tr>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Number</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Name</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Approx time</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Online</th>
              </tr>
            </thead>
            <tbody style={{height:"10px !important", fontFamily:"sans-serif", fontSize:"13px"}}>
            {memorizationSessions.length > 0 && memorizationSessions.filter( ms=> ms.status === SessionStatus.Waiting).map((memorizationSession, index) => (
                      <tr onClick={UserHasRoles(userContext,["Teacher"]) || userContext.studentId === memorizationSession.student.studentId
                                  ? ()=> rowClickStudent(memorizationSession.memorizationSessionId)
                                  : () => {}
                      } key={memorizationSession.memorizationSessionId}>
                          <td>
                            {index + 1}
                          </td>
                          <td>
                            {memorizationSession.student.fullName}
                          </td>
                          {memorizationSession.approxStartTime ? <td>
                            {GetFormattedTimeString(memorizationSession.approxStartTime.toString())}
                          </td> :
                          <td>
                          {}
                          </td>
                          }
                          <td>
                            {<Form>
                              <Form.Check 
                                type="checkbox"
                                id="custom-checkbox"
                                disabled= {true}
                                defaultChecked={memorizationSession.online}
                              />
                            </Form>}
                          </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
        </div>
        </Tab>
        {UserHasRoles(userContext,["Teacher"]) && <Tab eventKey={2} title="My sessions">
          <Table striped bordered hover>
            <thead style={{ 
              position: "sticky", 
              top: 0, 
              background:"#135128", 
              color:"white", 
              fontFamily:"sans-serif",
              fontSize:"15px"}}>
              <tr>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Number</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Name</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Approx time</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Online</th>
              </tr>
            </thead>
            <tbody>
            {teacherMemorizationSessions.length > 0 && teacherMemorizationSessions.filter( ms=> ms.status === SessionStatus.Inprog).map((teacherMemorizationSession, index) => (
                      <tr onClick={()=> rowClickTeacher(teacherMemorizationSession.student.studentId)} key={teacherMemorizationSession.memorizationSessionId}>
                          <td>
                            {index + 1}
                          </td>
                          <td>
                            {teacherMemorizationSession.student.fullName}
                          </td>
                          {teacherMemorizationSession.approxStartTime ? <td>
                            {GetFormattedTimeString(teacherMemorizationSession.approxStartTime.toString())}
                          </td> :
                          <td>
                          {}
                          </td>
                          }
                          <td>
                            {<Form>
                              <Form.Check 
                                type="checkbox"
                                id="custom-checkbox"
                                disabled= {true}
                                defaultChecked={teacherMemorizationSession.online}
                              />
                            </Form>}
                          </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
        </Tab>}
        {<Tab eventKey={3} title="Video Session">
            {<div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
                <input placeholder='student number' 
                  required
                  id="studentNumber"
                  disabled={isStudent}
                  hidden={isStudent}
                  value={channelNameState}
                  onChange={handleChangeChannelName}
                  style={{
                    marginLeft:"5px", 
                    marginRight:"5px", 
                    width:"50%", 
                    height: "40px", 
                    borderRadius:"6px",
                    }} 
                  type="text">
                </input>
              </div>}
              <div style={{marginLeft:"5px"}}>
              <Button 
                  disabled={false} 
                  style={{backgroundColor:"#1D5F35"}} 
                  onClick={()=>{startVideoCall()}}>Start video call</Button>
              <AgoraModal 
                  showModal={showAgoraModal}
                  channelName={channelNameState}
                  onHideModal={hideAgoraModal} />
              </div>
          </Tab>}
      </Tabs>
    </>
  );
}


import { useCallback, useState } from 'react';
import './header.css';
import hifzcentericon from '../../image/hifzcentericon.png';
import { Link } from "react-router-dom";
import { Dropdown} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { UserHasRoles } from '../../user/role';
import { useUserContext } from '../../user/user-context';

export function Header() {

  const [openSideNavState, setOpenSideNav] = useState(false);
  const {userContext} = useUserContext();

  const [iconClicked, setIconClicked] = useState(false);

  const openSideNav = () => {
    const sideNav = document.getElementById("mysidenav");
    if(sideNav){
      sideNav.style.width = "250px";
      setOpenSideNav(true);
    }
  }

  const closeSideNav = () => {
    const sideNav = document.getElementById("mysidenav");
    if(sideNav){
      sideNav.style.width = "0";
      setOpenSideNav(false);
    }
  }

  const handleIconClick = () => {
    setIconClicked(!iconClicked);
  };

  const CustomToggle = useCallback(React.forwardRef<HTMLAnchorElement, any>((props, ref) => (
    <a
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        handleIconClick();
        props.onClick(e);
      }}
      className={`custom-toggle ${iconClicked ? 'white' : 'black'}`}
    >
      <FontAwesomeIcon icon={faUser} color={iconClicked ? 'white' : 'white'} />
    </a>
  )), []);

  const handleClick = (event:any) => {
    event.preventDefault();
    // handle click event here
  };
  

  return (
    <div>
      <div className="App">
        <ul style={{width:"100%"}}>
          {UserHasRoles(userContext,["Administrator","Teacher"]) 
          ? <li style={{width:"5%"}}><img style={{marginTop:"11px", marginLeft:"-25px"}} src={hifzcentericon} alt="Logo" /></li>
          : <li style={{width:"5%"}}><img style={{marginTop:"11px", marginLeft:"-25px"}} src={hifzcentericon} alt="Logo" /></li>}
          {UserHasRoles(userContext,["Administrator"]) 
          ? <li style={{width:"25%", fontFamily:"sans-serif"}}><Link to="/">Sessions</Link></li>
          : <li style={{width:"40%", fontFamily:"sans-serif"}}><Link to="/">Sessions</Link></li>}
          {UserHasRoles(userContext,["Administrator"]) 
          ? <li style={{width:"25%", marginLeft:"0px" ,fontFamily:"sans-serif"}}><Link className="link" to="/memorization">Hifz</Link></li>
          : <li style={{width:"30%", marginLeft:"0px", fontFamily:"sans-serif"}}><Link className="link" to="/memorization">Hifz</Link></li>}
           {UserHasRoles(userContext,["Administrator"]) && <li style={{width:"25%", marginLeft:"0px", fontFamily:"sans-serif", marginTop:"-1px"}}>
           <Dropdown style={{position:"inherit"}}>
              <Dropdown.Toggle style={{
                  backgroundColor:"#333333", 
                  border: "none",
                  marginTop: "10px",
                  marginRight:"0px"}} variant="success" id="dropdown-basic">
                Admin
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/*<Dropdown.Item href="/studentabsence">Student absence</Dropdown.Item>*/}
                <Dropdown.Item href="/studentsabsence">Student absence report</Dropdown.Item>
                <Dropdown.Item href="/registerpayment">Register Payment</Dropdown.Item>
                <Dropdown.Item href="/reportpayment">Payment report</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>}
         {<li style={{ marginLeft: UserHasRoles(userContext,["Administrator","Teacher"]) ? "15px" : "0px", zIndex:"9999999999" }}>
           <Dropdown style={{position:"inherit"}}>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-toggle" />
              <Dropdown.Menu>
                {UserHasRoles(userContext,["Administrator"]) && <Dropdown.Item href="/createprofile">Create profile</Dropdown.Item>}
                {<Dropdown.Item href="/editprofile">Edit profile</Dropdown.Item>}
                <Dropdown.Item href="/logout">Log out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>}
        </ul>
      </div>
    </div>
  );
}

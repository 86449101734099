import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AgoraContainer } from './agora-container';

export interface IAgoraModal {
  showModal: boolean;
  channelName: string;
  onHideModal: () => void;
}

function AgoraModal(props: IAgoraModal) {

    const {showModal, channelName, onHideModal} = props;

    const [leaveChannelState, setLeaveChannel] = useState(false);

    const onCloseModal = () => {
        onHideModal();
        setLeaveChannel(true);
    };

    const handleLeaveChannel = () => {
        setLeaveChannel(false);
        onHideModal();
    }

    return (
      <>
        <Modal show={showModal} onHide={onCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Hifz/Murajara session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{height:"80vh"}}>
              <AgoraContainer 
                channelName={channelName}
                leaveChannel={leaveChannelState}
                onLeaveChannel={handleLeaveChannel}/>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default AgoraModal;
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { IMemorizationSession } from '../../models/memorization-session';
import { MemorizationType, SessionStatus } from '../../enums/status-enums';
import { Table } from 'react-bootstrap';
import './memorization-session-teacher-modal.css';
import { ICompleteMemorizationSessionRequest } from '../../models/complete-memorization-session-request';
import AgoraModal from '../agora/agora-modal';

interface IMemorizationSessionErrors{
  memorizationGradeError: string;
  repititionGradeError: string;
}

export interface IMemorizationSessionModal {
    showModal: boolean;
    onHideModal: () => void;
    memorizationSession: IMemorizationSession;
    onStartSession: (memorizationSessionId: string) => void;
    onCompleteSession: (completeMemorizationSessionRequest: ICompleteMemorizationSessionRequest) => void;
    onAddBackToQueue: (studentId: string)=> void;
}

function MemorizationSessionTeacherModal(props: IMemorizationSessionModal) {

   const {showModal, memorizationSession, onHideModal, onStartSession, onAddBackToQueue, onCompleteSession} = props;

   const [selectedMemorizationGrade, setMemorizationGrade] = useState("");
   const [selectedRepetitionGrade, setRepetitionGrade] = useState("");
   const [showAgoraModal, setshowAgoraModal] = useState(false);

   const [errorState, setErrorState] = useState({
    error: { } as IMemorizationSessionErrors
   });

    const onCloseModal = () => {
        onHideModal();
        setMemorizationGrade("");
        setRepetitionGrade("");
        setErrorState({error: {} as IMemorizationSessionErrors});
    };

    const onClickStartSession = ()=> {
        onStartSession(memorizationSession.memorizationSessionId);
    }

    const onClickCompleteSession = ()=> {

      if(checkForErrors()) return;

      onCompleteSession({
        memorizationSessionId: memorizationSession.memorizationSessionId,
        memorizationId: memorizationSession.memorization.memorizationId,
        teacherId: memorizationSession.teacher.teacherId,
        memorizationGrade: Number(selectedMemorizationGrade),
        repetitionGrade: Number(selectedRepetitionGrade)
      } as ICompleteMemorizationSessionRequest);
      
      onHideModal();
     }
    
    const onClickAddBackToQueue = ()=>{
      onAddBackToQueue(memorizationSession.memorizationSessionId);
      onHideModal();
    }

    const handleSelectMemorizationGrade=(e:any)=>{
      setMemorizationGrade(e);
    }
  
    const handleSelectRepitionGrade=(e:any)=>{
      setRepetitionGrade(e);
    }

    const getGradeName = (selectedElement: string)=> {
      switch(selectedElement){
        case "1":
          return "Perfect";
        case "2":
          return "Very good";
        case "3":
          return "Good";
        case "4":
          return "Have not memorized";
      }
    }

    const checkForErrors = ()=> {
  
      let errors = {} as IMemorizationSessionErrors;
      if(!selectedMemorizationGrade){
         errors.memorizationGradeError = "Hifz grade value must be selected" 
      }
      if(!selectedRepetitionGrade){
        errors.repititionGradeError = "Murajara grade value must be selected" 
      }

      setErrorState({
        error: errors
      });
  
      if(errors.memorizationGradeError || errors.repititionGradeError){  
        return true;
      }

      return false;
    }

    const startVideoCall = ()=> {
      setshowAgoraModal(true);
    }

    const hideAgoraModal = ()=> {
      setshowAgoraModal(false);
    }

    return (
      <>
        <AgoraModal 
            showModal={showAgoraModal}
            channelName={memorizationSession?.student?.number.toString()}
            onHideModal={hideAgoraModal} />
        <Modal show={showModal} onHide={onCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Hifz session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          <label style={{marginBottom: "10px"}}>Name: {memorizationSession?.student?.fullName}</label>
          <br></br>
          <label style={{marginBottom: "10px"}}>Phone: {memorizationSession?.student?.phone}</label>
          <br></br>
          <label style={{marginBottom: "10px"}}>Student number: {memorizationSession?.student?.number}</label>
          <br></br>
          {<div style={{display: "flex" , marginBottom: "10px"}}><label>Online: </label>
            {memorizationSession.status === SessionStatus.Inprog  ? <div style={{marginLeft:"5px", marginTop:"-5px"}}>
            <Button disabled={!memorizationSession.online || (memorizationSession.memorization && !memorizationSession.memorization.memorizationChapters)} style={{backgroundColor:"#1D5F35"}} onClick={()=>{startVideoCall()}}>Start video call</Button>
            </div> :<Form>
              <Form.Check 
                type="checkbox"
                id="custom-checkbox"
                disabled={true}
                style={{marginLeft: "5px"}}
                defaultChecked={memorizationSession.online}
              />
            </Form>}
            </div>}
        
          <br></br>
          {memorizationSession?.memorization?.memorizationChapters?.length > 0 &&  <Table striped bordered hover>
            <thead>
              <tr>
                <th>Surah</th>
                <th>From</th>
                <th>To</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
            {memorizationSession.memorization.memorizationChapters.map((memorizationChapter, index) => (
                      <tr  key={index}>
                          <td>
                            {`${memorizationChapter.name} (${memorizationChapter.nameArabic})`}
                          </td>
                          <td>
                            {memorizationChapter.fromAyah}
                          </td>
                          <td>
                            {memorizationChapter.toAyah}
                          </td>
                          <td>
                            {memorizationChapter.memorizationType === MemorizationType.Hifz ? "Hifz" : "Murajara"}
                          </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
         } 
          {memorizationSession?.memorization && memorizationSession.memorization.memorizationChapters &&
           memorizationSession.status === SessionStatus.Inprog  && <div style={{display: "flex" ,marginBottom: "10px"}}>
            <label style={{marginBottom: "10px" }}>Hifz grade:
            </label>
            { <DropdownButton 
              className="custom-dropdown" 
              onSelect={handleSelectMemorizationGrade} 
              style={{marginLeft: "45px", marginTop: "-5px"}} 
              key="dropdownkey" 
              id="dropdown-basic-button" title={!selectedMemorizationGrade ? "Hifz grade" : getGradeName(selectedMemorizationGrade)}
              disabled={!memorizationSession.memorization.memorizationChapters}>
              <Dropdown.Item eventKey="1">Perfect</Dropdown.Item>
              <Dropdown.Item eventKey="2">Very good</Dropdown.Item>
              <Dropdown.Item eventKey="3">Good</Dropdown.Item>
              <Dropdown.Item eventKey="4">Have not memorized</Dropdown.Item>
            </DropdownButton>
            }
          </div>}
          {errorState.error.memorizationGradeError &&  <label style={{marginBottom: "10px", color:"red"}}>{errorState.error.memorizationGradeError}</label>}
          {memorizationSession?.memorization && memorizationSession.memorization.memorizationChapters &&
           memorizationSession.status === SessionStatus.Inprog  && <div style={{display: "flex" ,marginBottom: "10px"}}>
            <label style={{marginBottom: "10px" }}>Murajara grade:
            </label>
            { <DropdownButton 
              className="custom-dropdown" 
              onSelect={handleSelectRepitionGrade} 
              style={{marginLeft: "10px", marginTop: "-5px"}} 
              key="dropdownkey" 
              id="dropdown-basic-button" title={!selectedRepetitionGrade ? "Murajara grade" : getGradeName(selectedRepetitionGrade)}
              disabled={!memorizationSession.memorization.memorizationChapters}>
              <Dropdown.Item eventKey="1">Perfect</Dropdown.Item>
              <Dropdown.Item eventKey="2">Very good</Dropdown.Item>
              <Dropdown.Item eventKey="3">Good</Dropdown.Item>
              <Dropdown.Item eventKey="4">Have not memorized</Dropdown.Item>
            </DropdownButton>
            }
          </div>}
          {errorState.error.repititionGradeError &&  <label style={{marginBottom: "10px", color:"red"}}>{errorState.error.repititionGradeError}</label>}
          {memorizationSession.status === SessionStatus.Inprog && 
          memorizationSession?.memorization && 
          !memorizationSession.memorization.memorizationChapters && 
          <label style={{color:"red", marginLeft:"5px"}}>No memorization homework is available. Create homework before you continue with this session</label>}
          </div>
          </Modal.Body>
          <Modal.Footer>
          {<Button style={{backgroundColor:"#1D5F35"}} variant="secondary" onClick={onCloseModal}>
              Cancel
            </Button>}
           {memorizationSession.status === SessionStatus.Inprog && <Button style={{backgroundColor:"#1D5F35"}} variant="primary"
            onClick={()=>onClickAddBackToQueue()}>
              Add back to queue
            </Button>}
            {memorizationSession.status === SessionStatus.Waiting && <Button  style={{backgroundColor:"#1D5F35"}} variant="primary" onClick={onClickStartSession}>
              Start Session
            </Button> 
            }
            {memorizationSession.status === SessionStatus.Inprog && 
            <Button style={{backgroundColor:"#1D5F35"}} disabled={!memorizationSession.memorization.memorizationChapters} variant="primary" onClick={onClickCompleteSession}>
              Complete Session
            </Button>}
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default MemorizationSessionTeacherModal;
import React, { ChangeEvent, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IStudent } from '../../models/student';
import { Col, Form } from "react-bootstrap";
import './memorization-container.css';
import { Chapters } from '../../consts/surah-const';
import { IMemorizationChapter } from '../../models/memorization-chapter';
import { MemorizationChapterTable } from './memorization-chapter-table';
import { IMemorization } from '../../models/memorization';
import { MemorizationChapterOptionStatus, MemorizationStatus } from '../../enums/status-enums';
import { ITeacher } from '../../models/teacher';
import { UserHasRoles } from '../../user/role';
import { useUserContext } from '../../user/user-context';
import MemorizationChapterOptionModal from './memorization-chapter-option-modal';

export interface IMemorizationHomeWorkModal {
   onCreateMemorizationHomeWork: (memorization: IMemorization) => void;
   student: IStudent;
   teacher: ITeacher;
   hasUncompletedMemorizationHomeWork: boolean;
}

function MemorizationHomeWorkModal(props: IMemorizationHomeWorkModal) {

   const {student, teacher, onCreateMemorizationHomeWork, 
    hasUncompletedMemorizationHomeWork} = props;

   const [show, setShow] = useState(false);
   const [memorizationChaptersState, setMemorizationChapters] = useState([] as IMemorizationChapter[]);

   const {userContext} = useUserContext();

   const handleClose = () => 
   {
      setShow(false);
      setMemorizationChapters([]);
   };

   const handleShow = () => setShow(true);

  const handleChangeMemorizationChapter = (memorizationChapter: IMemorizationChapter)=> {
    setMemorizationChapters(
      memorizationChaptersState.map(mc => 
        mc.chapterId === memorizationChapter.chapterId && mc.memorizationType === memorizationChapter.memorizationType
        ? memorizationChapter 
        : mc)
    )
  }

  const handleCreateMemorizationHomeWork = () => {
    const memorization = {
          memorizationChapters: memorizationChaptersState,
          student: student,
          teacher: teacher
    } as IMemorization;
    onCreateMemorizationHomeWork(memorization);
    setShow(false);
    setMemorizationChapters([]);
  }

  const handleUpdateMemorizationChapters = (memorizationChapters: IMemorizationChapter[]) => {
    setMemorizationChapters(memorizationChapters);
  }


  return (
      <>
       {!hasUncompletedMemorizationHomeWork && UserHasRoles(userContext,["Teacher"]) &&
        <Button style={{backgroundColor:"#1D5F35", marginLeft: "9px", marginBottom: "15px"}} variant="primary" onClick={handleShow}>
          Create hifz homework
        </Button>}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Hifz homework</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
         <div>
              <label style={{marginBottom: "20px"}}>Student: {student.fullName}</label>
              <br></br>
              <div style={{marginBottom: "20px"}}>
                <label style={{marginBottom: "10px"}}>Surah:
                </label>
                {
                  <MemorizationChapterOptionModal 
                      memorizationChapters={memorizationChaptersState}
                      onUpdateMemorizationChapters={handleUpdateMemorizationChapters}
                      memorizationChapterOptionStatus= {MemorizationChapterOptionStatus.New} />
                 }
              </div>
              <div>
                <MemorizationChapterTable memorizationChapters={memorizationChaptersState}
                onChangeMemorizationChapter = {handleChangeMemorizationChapter} />
              </div>
          </div>
          <br></br>
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary"  style={{backgroundColor:"#1D5F35"}} onClick={handleClose}>
              Cancel
            </Button>
             <Button variant="primary" style={{backgroundColor:"#1D5F35"}} onClick={handleCreateMemorizationHomeWork}>
              Create homework
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default MemorizationHomeWorkModal;
import React, { useEffect, useState } from 'react';
import { IMemorizationSession } from '../../models/memorization-session';
import { MemorizationSessionService, IMemorizationSessionService } from '../../services/memorization-session-service';
import { useUserContext } from '../../user/user-context';
import BookMemorizationSessionModal from './book-Memorization-session-modal';
import { MemorizationSessionTable } from './memorization-sessions-table';
import { ICompleteMemorizationSessionRequest } from '../../models/complete-memorization-session-request';
import { IMemorizationService, MemorizationService } from '../../services/memorization-service';
import { IMemorization } from '../../models/memorization';

export function MemorizationSessionContainer() {

  const [memorizationSessionState, setMemorizationSessionsState] = useState({
    memorizationSessions: [] as IMemorizationSession[],
  });

  const [memorizationState, setMemorizationState] = useState({
    memorization: {} as IMemorization,
  });

  const [teacherMemorizationSessionState, setTeacherMemorizationSessionsState] = useState({
    teacherMemorizationSessions: [] as IMemorizationSession[],
  });

  const memorizationSessionService: IMemorizationSessionService = new MemorizationSessionService();
  const memorizationService: IMemorizationService = new MemorizationService();

  const {userContext} = useUserContext();

  useEffect(() => {
    getMemorizationSessions();
  }, []);

  const getMemorizationSessions = async () => {
    await memorizationSessionService
      .getMemorizationSessions()
      .then((data) => {
        if (data) {
          setMemorizationSessionsState({ memorizationSessions: data });
        }
      });
  };

  const getOngoingMemorizationByStudentId = async (studentId: string) => {
    await memorizationService
      .getOngoingMemorizationByStudentId(studentId)
      .then((data) => {
        if (data) {
          setMemorizationState({ memorization: data });
        }
      });
  };

  const getTeacherMemorizationSessions = async () => {
    await memorizationSessionService
      .getMemorizationSessionsAssignedToTeacher(userContext.teacherId)
      .then((data) => {
        if (data) {
          setTeacherMemorizationSessionsState({ teacherMemorizationSessions: data });
        }
      });
  };

  const handleOnBookSession = async (online:boolean) => {
    await memorizationSessionService
    .bookMemorizationSessionByStudentId(userContext.studentId, online)
    .then((data) => {
      if (data) {
        setMemorizationSessionsState({ memorizationSessions: data });
      }
    });
  }

  const handleStartSession = async (memorizationSessionId: string) => {
    await memorizationSessionService
    .assignMemorizationSessionToTeacher(memorizationSessionId, userContext.teacherId)
    .then((data) => {
      if (data) {
        setMemorizationSessionsState({ memorizationSessions: data });
      }
    });
  }

  const handleAddBackToQueue = async (memorizationSessionId: string)=>{
    await memorizationSessionService
    .addMemorizationSessionBackToQueueAsync(memorizationSessionId)
    .then((data) => {
      if (data) {
        setTeacherMemorizationSessionsState({ teacherMemorizationSessions: data });
      }
    });
  }

  const handleCompleteSession = async (completeMemorizationSessionRequest: ICompleteMemorizationSessionRequest) => {
    await memorizationSessionService
    .completeMemorizationSession(completeMemorizationSessionRequest)
    .then((data) => {
      if (data) {
        setTeacherMemorizationSessionsState({ teacherMemorizationSessions: data });
      }
    });
  }

  const handleDeleteSession = async (memorizationId: string) => {
    await memorizationSessionService
    .deleteMemorizationSession(memorizationId)
    .then((data) => {
      if (data) {
        setMemorizationSessionsState({ memorizationSessions: data });
      }
    });
  }
  


  const studentHasBookedMemorizationSession = () => {
      return memorizationSessionState.memorizationSessions.length > 0 && 
      memorizationSessionState.memorizationSessions.some( ms => ms.student.studentId === userContext.studentId);
  }

  return (
    <>
    <BookMemorizationSessionModal onBookSession={handleOnBookSession}
      studentHasBookedMemorizationSession = {studentHasBookedMemorizationSession()}/>
    <div>
       <MemorizationSessionTable memorizationSessions={memorizationSessionState.memorizationSessions}
        teacherMemorizationSessions={teacherMemorizationSessionState.teacherMemorizationSessions}
        memorization={memorizationState.memorization}
        onStartSession={handleStartSession} 
        onAddBackToQueue={handleAddBackToQueue} 
        onCompleteSession={handleCompleteSession}
        onDeleteSession={handleDeleteSession}
        onTabStudentTabMemorizationSession= {getMemorizationSessions}
        onTabTeacherTabMemorizationSession= {getTeacherMemorizationSessions}
        onClickMemorizationRowTeacher ={getOngoingMemorizationByStudentId}/>
    </div>
  </>
  );
}


import './App.css';
import { BrowserRouter, Routes,  Route, Navigate, useLocation } from "react-router-dom";
import { BasePageLayout } from './base-page-layout';
import { MemorizationSessionContainer } from './components/memorization-session/memorization-session-container';
import { MemorizationContainer } from './components/memorization/memorization-container';
import { AuthWrapper } from './components/auth/auth-wrapper';
import { Login } from './components/auth/login';
import { Register } from './components/auth/register';
import { ForgotPassword } from './components/auth/forgot-password';
import { ResetPassword } from './components/auth/reset-password';
import { AuthContainer } from './components/auth/auth-container';
import { Logout } from './components/auth/logout';
import { StudentAbsenceContainer } from './components/absence/student-absence-container';
import { UserProfileContainer } from './components/administration/user-profile/user-profile-container';
import { EditUserProfileContainer} from './components/administration/edit-user-profile/edit-user-profile-container';
import { StudentsAbsenceContainer } from './components/absence/students-absence-container';
import { RegisterPaymentContainer } from './components/payment/register-payment-container';
import { ReportPaymentContainer } from './components/payment/report-payment-container';
import { Term } from './components/terms/terms';

function App() {

  return (
    <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/resetpassword" element={<ResetPassword/>} />
              <Route path="/terms" element={<Term/>} />
              <Route element={<AuthContainer />}>
                   <Route element={<BasePageLayout />}>
                      <Route path="/" element={<MemorizationSessionContainer />} />
                      <Route path="/memorization" element={<MemorizationContainer />} />
                      <Route path="/studentabsence" element={<StudentAbsenceContainer />} />
                      <Route path="/studentsabsence" element={<StudentsAbsenceContainer />} />
                      <Route path="/registerpayment" element={<RegisterPaymentContainer />} />
                      <Route path="/createprofile" element={<UserProfileContainer />} />
                      <Route path="/editprofile" element={<EditUserProfileContainer />} />
                      <Route path="/reportpayment" element={<ReportPaymentContainer/>} />
                  </Route>
                  <Route element={<AuthWrapper />}>
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/register" element={<Register/>} />
                    <Route path="/forgotpassword" element={<ForgotPassword/>} />
                  </Route>
              </Route>
            </Routes>
    </BrowserRouter>
  );
}

export default App;
